.cooperationSection {
  min-height: 70vh; /* Mehr Höhe für bessere Darstellung */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
}

.abtrennungsLinie {
  border: 1px solid black;
  width: 98%;
  margin: 0px 0px 3rem 0px;
}

/* NEUE TRENNLINIE zwischen den Abschnitten */
.abtrennungsLinie-zusatz {
  border: 1px solid black;
  width: 70%; /* Kürzere Linie */
  margin: 3rem 0; /* Mehr Abstand */
}

.cooperationHeader {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

/* Die ersten drei Logos */
.cooperation-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* Einzelne Bilder */
.cooperation-image {
  transition: transform 0.3s ease; /* Smooth transition for scale and shadow */
  max-height: 10rem;
  max-width: 14rem;
  padding: 2rem 12rem;
}

.cooperation-image:hover {
  transform: scale(1.1); /* Scale image by 10% */

}

/* NEUE KLASSEN: Damit das vierte Bild + Text unter den anderen steht */
.cooperation-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 6rem; /* Mehr Abstand nach unten */
}

.cooperation-item .cooperation-image {
  max-height: 16rem;
  max-width: 20rem;
  padding: 0;
}

.cooperation-text {
  font-size: 1rem;
  margin-top: 2rem;
  max-width: 20rem;
  text-align: center;

}

/* RESPONSIVE ANPASSUNGEN */
@media (max-width: 1550px) {
  .cooperation-image {
    max-height: 9rem;
    padding: 3rem 6rem;
  }
}

@media (max-width: 768px) {
  .cooperation-image {
    max-height: 10rem;
    padding: 2rem 4rem;
  }
  .cooperationHeader {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .cooperation-item .cooperation-image {
    max-height: 10rem;
    max-width: 14rem;
  }

  .cooperation-text {
    font-size: 0.9rem;
    max-width: 14rem;
  }

  .abtrennungsLinie-zusatz {
    width: 98%; /* Längere Linie auf kleinen Bildschirmen */
  }
}
